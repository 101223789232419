<template>
  <component :is="tag">
    <template v-if="allDay">
      {{ $t('calendar.meeting-form.all-day') }}
    </template>
    <template v-else-if="!endDate">
      {{ startDate | moment("HH:mm") }}
    </template>
    <template v-else>
      {{ range }}
    </template>
  </component>
</template>

<script>
export default {
  name: 'EventPeriod',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    allDay: Boolean,
    startDate: {
      type: [Number, Date],
      default: null,
    },
    endDate: {
      type: [Number, Date],
      default: null,
    },
  },
  computed: {
    range() {
      const startMoment = this.startDate ? this.$moment.utc(this.startDate) : null;
      const endMoment = this.endDate ? this.$moment.utc(this.endDate) : null;
      const startString = startMoment.format('HH:mm');
      const endString = endMoment.format('HH:mm');

      let isDiffDay = false;

      if (startMoment.dayOfYear() !== endMoment.dayOfYear()) {
        isDiffDay = true;
        // endString = `${endMoment.format('D MMM')}, ${endString}`;
      }

      return isDiffDay ? `${startString}h - ${endString}h` : `${startString}h - ${endString}h`;
    },
  },
};
</script>
